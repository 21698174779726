import Separator from "@/shared/Separator";
import { ParentGrid , Card} from "./style";
import { GridStyleColor } from "@/widgets/Container/Templates/stylesGridGeneric.js";
const ContainerExclusivo = (props) => {
  const { link, sectionClass = "", title, linkImage } = props;
  const items = [];
  for (let index = 0; index < 4; index++) {
    items.push(
      <Card className={`div${index + 1}`} key={`container${index + 1}`}>
        {props.renderContainerWidgetCallback(
          index + 1,
          props.positionProperties?.[index]?.templateNews ?? "OnexTwo", { containerName: "contenedor_exclusivo"}
        )}
      </Card>
    );
  }
  const marfeelTitle = title ? "ContainerExclusivo-"+title?.normalize("NFD")
  .replace(/[\u0300-\u036f]/g, "")
  .replace(/\s+/g, '-')
  .toLowerCase() : "ContainerExclusivo"; 
  return (
    props.content?.length > 0 && <>
      {title && <Separator title={title} link={link} sectionClass={sectionClass} linkImage={linkImage} />}
      <GridStyleColor  id={marfeelTitle}>
        <ParentGrid data-mrf-recirculation={`${marfeelTitle}`} className={`ContainerExclusivo module-grid ${sectionClass}`}>
          {items}
        </ParentGrid>
      </GridStyleColor>
    </>
  );
};
export default ContainerExclusivo;